.App {
  text-align: center;
  font-family: sans-serif;
  padding: 3em;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img {
  width: 9em;
}

.tick {
  font-size: 5.5em;
}

@media screen and (max-width: 280px) {
  .tick-flip {
    font-size: 18px;
  }
  .tick {
    width: 50vw;
  }
}

@media screen and (max-width: 320px) and (min-width: 281px) {
  .tick-flip {
    font-size: 25px;
  }
  .tick {
    width: 60vw;
  }
}

@media screen and (max-width: 360px) and (min-width: 321px) {
  .tick-flip {
    font-size: 30px;
  }
  .tick {
    width: 70vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 361px) {
  .tick-flip {
    font-size: 40px;
  }
  .tick {
    width: 80vw;
  }
}

@media screen and (max-width: 1000px) and (min-width: 768px) {
  .tick-flip {
    font-size: 60px;
  }
  .tick {
    width: 90vw;
  }
}

@media screen and (max-width: 1440px) and (min-width: 1001px) {
  .tick-flip {
    font-size: 80px;
  }
  .tick {
    width: 100vw;
  }
}

.bitcoin-logo {
  width: 132px;
  height: 29px;
  vertical-align: middle;
}
